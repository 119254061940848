import { Dialog } from 'primereact/dialog';
import Input from '../../../Components/Input/Input';
import { useState } from 'react';
import Button from '../../../Components/Button/Button';
import { createRagConfig } from '../../../Tools/Api';
import { getCurrentBot } from '../../../Redux/Bot/selectors';
import { useSelector } from 'react-redux';
import { useRagConfig } from '../../../Contexts/RagConfigContext';
import { useUserContext } from '../../../Contexts/UserContext';

interface ModalCreateRagProps {
  onHide: () => void;
  visible: boolean;
}

const ModalCreateRag = ({ onHide, visible }: ModalCreateRagProps) => {
  const [configName, setConfigName] = useState<string>('');
  const botUUID = useSelector(getCurrentBot)?.botUUID;
  const { ragConfigs, setRagConfigs } = useRagConfig();
  const { casClient } = useUserContext();

  const token = casClient.getToken();

  const handleCreateRag = () => {
    const data = {
      name: configName,
      botUUID: botUUID || '',
    };
    createRagConfig(data, token)
      .then((res) => {
        onHide();
        setRagConfigs && setRagConfigs([...(ragConfigs || []), res]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Dialog visible={visible} onHide={onHide} dismissableMask={true}>
      <>
        <h1>Création de votre rag</h1>
        <Input
          value={configName}
          onChange={(e) => setConfigName(e.target.value)}
          placeholder={'Entrez le bom de votre config'}
          label={'Nom de la configuration'}
          maxLength={50}
        />
      </>
      <>
        <Button label={'Créer'} onClick={handleCreateRag} />
      </>
    </Dialog>
  );
};

export default ModalCreateRag;
