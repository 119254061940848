import FilesList from './Components/FilesList';
import SharePointList from './Components/SharePointList';
import { DisplayContainer } from './Style';

const Display = () => {
  return (
    <DisplayContainer>
      <FilesList />
      <SharePointList />
    </DisplayContainer>
  );
};

export default Display;
