import * as Actions from '../actions/websiteActions';
import * as Constants from 'src/Tools/Constants';
import * as Types from '../types';

import { all, call, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { getConfigurationsList, getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { getUserName, getUserProxy } from 'src/Redux/User/selectors';
import { toastError, toastSuccess } from 'src/Tools/Toast';
import Releases from 'src/Tools/releases/release-updater';

import { ApiProps } from '@dydu_ai/dydu-api';
import LocalStorage from 'src/Tools/LocalStorage';
import { closeModal } from 'src/Redux/Modal/actions';
import { encodeBase64 } from 'src/Tools/Text';
import { getClientApi } from 'src/Tools/Api';
import { getCurrentBot } from 'src/Redux/Bot/selectors';
import { push } from '@lagunovsky/redux-react-router';

import currentRelease from 'src/Tools/releases/versions/current/current';
import { saveAs } from 'file-saver';
import merge from 'deepmerge';
import { AVATAR_TYPE } from 'src/Tools/Constants';
import i18n from '../../../Services/i18n';
import { getCdnServerUrl } from 'src/Tools/DyduboxConfigurationUtils';

const extensionRegex = /(?:\.([^.]+))?$/;

/**
 * API CALLS
 */

/**
 * Get Website configurations List
 * @param botData
 * @returns
 */
function* getWebsiteConfigurationsList({ payload: { botData } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.backend.getWebsiteConfigurationsList, botData.botUUID);
    yield put(Actions.getWebsiteConfigurationsList.success(data));
  } catch (error) {
    yield put(Actions.getWebsiteConfigurationsList.failure(error));
    toastError(error);
  }
}

function* updateJsonsToLatest(config, wording) {
  config.content = Releases.updateContentToLatest(config.content);
  config.theme = Releases.updateThemeToLatest(config.theme);

  for (const w of wording) {
    if (w.translation) {
      const oldTranslation = w.translation;
      const newTranslation = Releases.updateTranslationToLatest(w.translation);

      w.translation = merge(newTranslation, oldTranslation, { arrayMerge: (_target, source) => source });

      if (w.language) {
        yield uploadTranslationJson({
          payload: {
            configId: config.id,
            lang: w.language,
            translation: w.translation,
            replaceIfExist: true,
          },
        });
      }
    }
  }
}

/**
 * Get Bot configuration Zip
 * @param envType
 * @returns
 */
function* getWebsiteConfigurationZip({ payload: { envType, configId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);

    let selected;

    // Get Selected from store
    selected = yield select(getSelectedConfig);

    // if empty get config from API if configId provided
    if (!selected && configId) {
      const { data } = yield call(Client.backend.getWebsiteConfiguration, configId);
      selected = data;
    }

    const cdnUrl: string = getCdnServerUrl(selected, envType);
    const { data } = yield* call(Client.public.getWebsiteConfigurationZip, cdnUrl);

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `configuration.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toastSuccess();

    yield put(Actions.getWebsiteConfigurationZip.success());
  } catch (error) {
    yield put(Actions.getWebsiteConfigurationZip.failure(error));
    toastError(error);
  }
}

/**
 * Get Website configuration
 * @param configId
 * @returns
 */
function* getWebsiteConfiguration({ payload: { configId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const proxy = yield select(getUserProxy);
    const { data: config }: { data: any } = yield* call(Client.backend.getWebsiteConfiguration, configId);

    const { data: wording } = yield* call(Client.backend.getWording, configId);

    const { data: images }: { data: API_BACKEND.Schemas.ImageConfigurationDTO[] } = yield* call(
      Client.backend.getImages,
      configId
    );

    const imagesData = extractImageDataFromResponse(images);

    yield updateJsonsToLatest(config, wording);
    updateContentProxy(config, proxy);

    const updatedConfig = JSON.parse(JSON.stringify(config));
    replaceImagesBytesToFilename(imagesData, updatedConfig);

    const imagesBytes: Models.ImagesData = {
      logo: imagesData.logo.bytes,
      teaser: imagesData.teaser.bytes,
      understood: imagesData.understood.bytes,
      misunderstood: imagesData.misunderstood.bytes,
      livechat: imagesData.livechat.bytes,
      reword: imagesData.reword.bytes,
      onboarding1: imagesData.onboarding1.bytes,
      onboarding2: imagesData.onboarding2.bytes,
      onboarding3: imagesData.onboarding3.bytes,
    };

    LocalStorage.removeItem(Constants.DyduStorage.IMAGES);
    LocalStorage.removeItem(Constants.DyduStorage.MAIN);
    LocalStorage.removeItem(Constants.DyduStorage.CSS);
    LocalStorage.removeItem(Constants.DyduStorage.WIZARD);

    LocalStorage.setItem(Constants.DyduStorage.IMAGES, JSON.stringify(imagesBytes));
    LocalStorage.setItem(Constants.DyduStorage.LOCALE, updatedConfig.content?.application?.defaultLanguage[0] || 'fr');
    LocalStorage.setItem(Constants.DyduStorage.WORDING, JSON.stringify(wording));

    // dydu.banner is used to keep in memory than the user close the info' banner,
    // dydu.onboarding to keep in memory that the user passed the onboarding.
    // We need to delete them to display the banner / onboarding(active) if we change configuration.
    LocalStorage.removeItem(Constants.DyduStorage.BANNER);
    LocalStorage.removeItem(Constants.DyduStorage.ONBOARDING);

    yield put(Actions.getImagesData.success(imagesBytes));
    yield put(Actions.getWebsiteConfiguration.success(updatedConfig));
  } catch (error) {
    yield put(Actions.getWebsiteConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Create Website configuration
 * @param data
 * @returns
 */
function* createWebsiteConfiguration({ payload: { data } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);

    const configData = {
      ...data,
      author: data.modifiedBy,
      content: currentRelease.content,
      theme: currentRelease.theme,
    };

    const { data: newConfig } = yield* call(Client.backend.createWebsiteConfiguration, configData);

    const languages = currentRelease.content.application?.languages || [];

    for (const language of languages) {
      yield uploadTranslationJson({
        payload: {
          configId: newConfig.id,
          lang: language,
          translation: currentRelease?.translation,
          replaceIfExist: true,
        },
      });
    }

    yield put(Actions.createWebsiteConfiguration.success(newConfig));
    yield put(closeModal());
    yield put(push(`${Constants.APP_PATH.CONFIGURATIONS}/${data.type}/${newConfig.id}`));
    toastSuccess();
  } catch (error) {
    yield put(Actions.createWebsiteConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Update Website configuration
 * @param config
 * @param options
 * @returns
 */
function* updateWebsiteConfiguration({ payload: { config, options } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);

    const selected = yield select(getSelectedConfig);
    const userName = yield select(getUserName);
    const proxy = yield select(getUserProxy);

    const selectedConfig = JSON.parse(JSON.stringify(config || selected));
    updateContentProxy(selectedConfig, proxy);

    const configData = {
      ...selectedConfig,
      modifiedBy: userName,
    };

    const { data } = yield* call(Client.backend.updateWebsiteConfiguration, configData);

    yield put(closeModal());

    toastSuccess();

    yield put(Actions.updateWebsiteConfiguration.success(data));

    // If redirect is setted at true, then redirect the user to home
    if (options?.redirect) {
      yield put(push(Constants.APP_PATH.CONFIGURATIONS));
    }

    // If publish is setted at true, then publish configuration to specify env
    if (options?.publish) {
      yield put(Actions.publishWebsiteConfiguration.request(config, options?.env, options?.version));
    }

    if (options?.uploadAvatar) {
      yield put(Actions.uploadWebsiteAvatar.request(options?.file, options?.type));
    }
  } catch (error) {
    yield put(Actions.updateWebsiteConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Update Bot configuration Name
 * @param name
 * @param configId
 * @returns
 */
function* updateWebsiteConfigurationName({ payload: { name, configId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.backend.updateWebsiteConfigurationName, configId, name);

    toastSuccess();

    yield put(Actions.updateWebsiteConfigurationName.success(data));
    yield put(closeModal());
  } catch (error) {
    yield put(Actions.updateWebsiteConfigurationName.failure(error));
    toastError(error);
  }
}

/**
 * Update Bot configuration Slug
 * @param slug
 * @param configId
 * @returns
 */
function* updateWebsiteConfigurationSlug({ payload: { slug, configId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.backend.updateWebsiteConfigurationSlug, configId, slug);

    toastSuccess();

    yield put(Actions.updateWebsiteConfigurationSlug.success(data));
    yield put(closeModal());
  } catch (error) {
    yield put(Actions.updateWebsiteConfigurationSlug.failure(error));
    toastError(error);
  }
}

/**
 * Import Bot configuration
 * @param type : website | customer
 * @param configName
 * @param configZip
 * @returns
 */
function* importWebsiteConfiguration({ payload: { type, configName, configZip, configUUID } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const formData = new FormData();
    formData.append(Constants.FORMDATA_TYPES.ZIPFILE, configZip);

    const userName = yield select(getUserName);
    const proxy = yield select(getUserProxy);
    const currentBot = yield select(getCurrentBot);

    const { data } = yield* call(Client.backend.importWebsiteConfiguration, {
      formData,
      name: configName,
      author: userName,
      type,
      botUUID: currentBot.botUUID,
      configUUID,
      ...(proxy && { proxy: encodeBase64(proxy) }),
    });

    toastSuccess();

    yield put(closeModal());
    yield put(push(Constants.APP_PATH.CONFIGURATIONS));
    yield put(Actions.importWebsiteConfiguration.success(data));
  } catch (error: any) {
    toastError(error);
    yield put(Actions.importWebsiteConfiguration.failure(error));
  }
}

/**
 * Delete Bot configuration
 * @param configId
 * @returns
 */
function* deleteWebsiteConfiguration({ payload: { configId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    yield call(Client.backend.deleteWebsiteConfiguration, configId);
    yield put(Actions.deleteWebsiteConfiguration.success(configId));

    toastSuccess();
    yield put(Actions.deleteWebsiteConfiguration.success(configId));

    yield put(closeModal());
  } catch (error) {
    yield put(Actions.deleteWebsiteConfiguration.failure(error));
    toastError(error);
  }
}

/**
 * Publish Website Configuration
 * @param env
 * @param config
 * @param version
 * @returns
 */
function* publishWebsiteConfiguration({ payload: { config, env, version } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const configList = yield select(getConfigurationsList);
    const userName = yield select(getUserName);
    const proxy = yield select(getUserProxy);
    let selectedConfig;

    if (typeof config === 'string') {
      const { data } = yield call(Client.backend.getWebsiteConfiguration, config);
      selectedConfig = data;
    } else {
      selectedConfig = config;
    }

    const publishData = {
      configId: selectedConfig.id,
      env,
      userName,
      version: version || Constants.CHATBOX_VERSION,
      ...(proxy && { proxy: encodeBase64(proxy) }),
    };

    const { data } = yield call(Client.backend.publishWebsiteConfiguration, publishData);

    const updatedConfigList = configList?.map((entry) =>
      entry?.id === selectedConfig?.id ? { ...entry, ...data } : entry
    );

    yield put(Actions.publishWebsiteConfiguration.success(data, updatedConfigList));
  } catch (error) {
    yield put(Actions.publishWebsiteConfiguration.failure(error));
    toastError(error);
  }
}

function* restorePublishedConfiguration({ payload: { configId, publicationId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const configList = yield select(getConfigurationsList);
    const proxy = yield select(getUserProxy);

    const restoreData = {
      configId: configId,
      publicationId: publicationId,
      ...(proxy && { proxy: encodeBase64(proxy) }),
    };

    const { data } = yield call(Client.backend.restorePublishedConfiguration, restoreData);

    const updatedConfigList = configList?.map((entry) => (entry?.id === configId ? { ...entry, ...data } : entry));

    toastSuccess(i18n.t('edition.restore.messages.success'));

    yield put(closeModal());
    yield put(Actions.restorePublishedConfiguration.success(data, updatedConfigList));
  } catch (error) {
    yield put(Actions.restorePublishedConfiguration.failure(error));
    toastError(i18n.t('edition.restore.messages.error'));
  }
}

/**
 * Upload translation file
 * @param param0
 */
function* uploadTranslationExcel({ payload: { configId, lang, translationFile } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const formData = new FormData();
    formData.append(Constants.FORMDATA_TYPES.FILE, translationFile);

    yield call(Client.backend.uploadTranslationExcel, formData, configId, lang);

    const { data } = yield call(Client.backend.getWording, configId);
    LocalStorage.setItem(Constants.DyduStorage.WORDING, JSON.stringify(data));

    toastSuccess();

    yield put(Actions.uploadTranslationExcel.success());
  } catch (error: any) {
    yield put(Actions.uploadTranslationExcel.failure(error));
    toastError(error);
  }
}

/**
 * Upload translation file
 * @param param0
 */
function* uploadTranslationJson({ payload: { configId, lang, translation, replaceIfExist } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);

    yield call(Client.backend.uploadTranslationJson, configId, lang, translation, replaceIfExist);

    const { data } = yield call(Client.backend.getWording, configId);
    LocalStorage.setItem(Constants.DyduStorage.WORDING, JSON.stringify(data));

    yield put(Actions.uploadTranslationJson.success());
  } catch (error: any) {
    yield put(Actions.uploadTranslationJson.failure(error));
    toastError(error);
  }
}

/**
 * Download translation file
 * @param param0
 */
function* downloadTranslation({ payload: { configId } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const { data } = yield* call(Client.backend.downloadTranslation, configId, currentRelease.translation);

    saveAs(new Blob([data]), 'Translation-All-Languages.xlsx');

    yield put(Actions.downloadTranslation.success());
  } catch (error: any) {
    yield put(Actions.downloadTranslation.failure(error));
    toastError(error);
  }
}

/**
 * Upload Website configuration Avatar
 * @param file
 * @param avatarType
 * @returns
 */
function* uploadWebsiteAvatar({ payload: { file, avatarType } }) {
  try {
    const Client: ApiProps = yield call(getClientApi);
    const selectedConfig = yield select(getSelectedConfig);

    const [, extension] = extensionRegex.exec(file.name) || 'png';
    const updatedContent = updateContentImageFilename(selectedConfig.content, avatarType, extension);

    const imagesData: Models.ImagesData = JSON.parse(
      JSON.stringify(LocalStorage.getItem(Constants.DyduStorage.IMAGES))
    );
    imagesData[avatarType.toLowerCase()] = yield call(convertFileToBase64, file);
    LocalStorage.setItem(Constants.DyduStorage.IMAGES, JSON.stringify(imagesData));

    const formData = new FormData();
    formData.append(Constants.FORMDATA_TYPES.IMAGE, file);
    formData.append('content', JSON.stringify(updatedContent));

    yield call(Client.backend.uploadImage, formData, selectedConfig.id, avatarType.toUpperCase());

    toastSuccess();

    yield put(Actions.getImagesData.success(imagesData));
    yield put(Actions.updateWebsiteConfigurationContent.success(updatedContent));
    yield put(Actions.uploadWebsiteAvatar.success());
  } catch (error) {
    yield put(Actions.uploadWebsiteAvatar.failure(error));
    toastError(error);
  }
}

function formatAvatarFilename(avatarType: string, extension: string) {
  let lowerCaseImageType: string = avatarType.toLowerCase();
  if (lowerCaseImageType.includes('onboarding')) {
    lowerCaseImageType =
      lowerCaseImageType.substring(0, lowerCaseImageType.length - 1) +
      '-' +
      lowerCaseImageType[lowerCaseImageType.length - 1];
  }

  return `dydu-${lowerCaseImageType}.${extension}`;
}

export function updateContentImageFilename(content: any, avatarType: string, extension: string) {
  const updatedContent = JSON.parse(JSON.stringify(content));

  const filename = formatAvatarFilename(avatarType, extension);
  switch (avatarType.toUpperCase()) {
    case AVATAR_TYPE.LOGO:
      updatedContent.avatar.response.image = filename;
      break;
    case AVATAR_TYPE.TEASER:
      updatedContent.avatar.teaser.image = filename;
      break;
    case AVATAR_TYPE.UNDERSTOOD:
      updatedContent.header.logo.imageLink.understood = filename;
      break;
    case AVATAR_TYPE.MISUNDERSTOOD:
      updatedContent.header.logo.imageLink.misunderstood = filename;
      break;
    case AVATAR_TYPE.LIVECHAT:
      updatedContent.header.livechatLogo.livechatImageLink = filename;
      break;
    case AVATAR_TYPE.REWORD:
      updatedContent.header.logo.imageLink.reword = filename;
      break;
    case AVATAR_TYPE.ONBOARDING1:
      updatedContent.onboarding.items[0].image.src = filename;
      break;
    case AVATAR_TYPE.ONBOARDING2:
      updatedContent.onboarding.items[1].image.src = filename;
      break;
    case AVATAR_TYPE.ONBOARDING3:
      updatedContent.onboarding.items[2].image.src = filename;
      break;
  }

  return updatedContent;
}

export function updateContentProxy(config, proxy: string | undefined | null) {
  let cdnUrl: string = window.DYDU_TARGETS.cdn_url;
  if (!cdnUrl.endsWith('/')) {
    cdnUrl += '/';
  }

  let proxifiedUrl: string = cdnUrl;

  if (proxy) {
    const url = new URL(cdnUrl);
    const baseUrl = url.protocol + '//' + url.host + (url.port ? ':' + url.port : '');
    proxifiedUrl = proxy + url.toString().substring(baseUrl.length);
  }

  config.content.application.cdn = proxifiedUrl;
}

function extractImageDataFromResponse(images: API_BACKEND.Schemas.ImageConfigurationDTO[]) {
  return images.reduce(
    (acc, v) => {
      const fileExt = v.fileExt === 'svg' ? 'svg+xml' : v.fileExt;
      return {
        ...acc,
        [v.imageTypeId.toLowerCase()]: {
          bytes: `data:image/${fileExt};base64,` + v.bytePic,
          extension: v.fileExt,
        },
      };
    },
    {
      logo: { bytes: '', extension: '' },
      teaser: { bytes: '', extension: '' },
      understood: { bytes: '', extension: '' },
      misunderstood: { bytes: '', extension: '' },
      livechat: { bytes: '', extension: '' },
      reword: { bytes: '', extension: '' },
      onboarding1: { bytes: '', extension: '' },
      onboarding2: { bytes: '', extension: '' },
      onboarding3: { bytes: '', extension: '' },
    }
  );
}

export function replaceImagesBytesToFilename(imagesData, config) {
  const onBoardingImages = [
    formatAvatarFilename(AVATAR_TYPE.ONBOARDING1, imagesData.onboarding1.extension),
    formatAvatarFilename(AVATAR_TYPE.ONBOARDING2, imagesData.onboarding2.extension),
    formatAvatarFilename(AVATAR_TYPE.ONBOARDING3, imagesData.onboarding3.extension),
  ].map((item, index) => ({
    disabled: config.content.onboarding.items?.[index]?.disabled || false,
    image: {
      src: item,
      hidden: config.content.onboarding.items?.[index]?.image.hidden || false,
    },
  }));

  if (config.content) {
    config.content.avatar.response.image = formatAvatarFilename(AVATAR_TYPE.LOGO, imagesData.logo.extension);
    config.content.avatar.teaser.image = formatAvatarFilename(AVATAR_TYPE.TEASER, imagesData.teaser.extension);
    config.content.header.logo.imageLink.understood = formatAvatarFilename(
      AVATAR_TYPE.UNDERSTOOD,
      imagesData.understood.extension
    );
    config.content.header.logo.imageLink.misunderstood = formatAvatarFilename(
      AVATAR_TYPE.MISUNDERSTOOD,
      imagesData.misunderstood.extension
    );
    config.content.header.livechatLogo.livechatImageLink = formatAvatarFilename(
      AVATAR_TYPE.LIVECHAT,
      imagesData.livechat.extension
    );
    config.content.header.logo.imageLink.reword = formatAvatarFilename(AVATAR_TYPE.REWORD, imagesData.reword.extension);
    config.content.onboarding.items = onBoardingImages;
  }
}

function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Website Sagas
 */
export default function* websiteSaga() {
  try {
    yield all([
      takeLatest(Types.WEBSITE_CONFIGURATION.GET_ALL.REQUEST, getWebsiteConfigurationsList),
      takeLatest(Types.WEBSITE_CONFIGURATION.GET_ONE.REQUEST, getWebsiteConfiguration),
      takeLatest(Types.WEBSITE_CONFIGURATION.UPDATE_ONE.REQUEST, updateWebsiteConfiguration),
      takeLatest(Types.WEBSITE_CONFIGURATION.UPDATE_NAME.REQUEST, updateWebsiteConfigurationName),
      takeLatest(Types.WEBSITE_CONFIGURATION.UPDATE_SLUG.REQUEST, updateWebsiteConfigurationSlug),
      takeLatest(Types.WEBSITE_CONFIGURATION.CREATE_ONE.REQUEST, createWebsiteConfiguration),
      takeLatest(Types.WEBSITE_CONFIGURATION.DELETE_ONE.REQUEST, deleteWebsiteConfiguration),
      takeLatest(Types.WEBSITE_CONFIGURATION.IMPORT_ONE.REQUEST, importWebsiteConfiguration),
      takeEvery(Types.WEBSITE_CONFIGURATION.PUBLISH_ONE.REQUEST, publishWebsiteConfiguration),
      takeEvery(Types.WEBSITE_CONFIGURATION.RESTORE_ONE.REQUEST, restorePublishedConfiguration),
      takeLatest(Types.WEBSITE_CONFIGURATION.UPLOAD_AVATAR.REQUEST, uploadWebsiteAvatar),
      takeLatest(Types.WEBSITE_CONFIGURATION.ZIP.REQUEST, getWebsiteConfigurationZip),
      takeLatest(Types.TRANSLATION.UPLOAD_EXCEL.REQUEST, uploadTranslationExcel),
      takeLatest(Types.TRANSLATION.UPLOAD_JSON.REQUEST, uploadTranslationJson),
      takeLatest(Types.TRANSLATION.DOWNLOAD.REQUEST, downloadTranslation),
    ]);
  } catch (error) {
    toastError(error);
  }
}
