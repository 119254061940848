import Button from '../../../Components/Button/Button';
import ModalCreateRag from './ModalCreateRag';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import TypeList from '../../Configurations/Components/TypeList';
import { RagConfigProps, useRagConfig } from '../../../Contexts/RagConfigContext';
import { useUserContext } from '../../../Contexts/UserContext';
import { deleteRagConfiguration } from '../../../Tools/Api';
import Icon from '../../../Components/Icon/Icon';
import { IconTrigger } from '../Style';
import { APP_PATH } from '../../../Tools/Constants';

const ConfigurationsRag = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ragConfigs, setRagConfigs, setSelectedRagConfiguration } = useRagConfig();
  const { casClient } = useUserContext();
  const [visible, setVisible] = useState<boolean>(false);

  const token = casClient.getToken();

  const ragColumns = [
    {
      id: 'name',
      title: 'Nom',
    },
    {
      id: 'author',
      title: 'Auteur',
    },
    {
      id: 'createdAt',
      title: 'Date de création',
      body: (rowData: any) => {
        return new Date(rowData.createdAt).toLocaleDateString();
      },
    },
    {
      id: 'updatedAt',
      title: 'Dernière modification',
      body: (rowData: any) => {
        return new Date(rowData.updatedAt).toLocaleDateString();
      },
    },
    {
      id: 'actions',
      title: 'Actions',
      body: (rowData: any) => {
        return (
          <IconTrigger
            onClick={(event) => {
              event.stopPropagation();
              deleteRagConfiguration(rowData.id, token).then(() => {
                ragConfigs &&
                  setRagConfigs &&
                  setRagConfigs(ragConfigs?.filter((config: RagConfigProps) => config.id !== rowData.id));
              });
            }}
          >
            <Icon color='red' size={40} icon='icon-trash' />
          </IconTrigger>
        );
      },
    },
  ];

  const handleOpenConfig = (id: string, type: string) => {
    const foundConfig = ragConfigs?.find((config: RagConfigProps) => config.id === id);
    if (foundConfig && setSelectedRagConfiguration) {
      setSelectedRagConfiguration(foundConfig);
    }
    navigate(`${APP_PATH.CONFIGURATIONS}/${type}/${id}`);
  };

  const listRagConfigurationWithType =
    ragConfigs && ragConfigs.length > 0
      ? ragConfigs?.map((config) => {
          return {
            ...config,
            ...{
              type: 'rag',
            },
          };
        })
      : [];

  const renderList = (configs: any, columns: any, title: string, subtitle: string, action: ReactNode) => (
    <TypeList
      data={configs}
      columns={columns}
      openConfig={handleOpenConfig}
      title={title}
      subtitle={subtitle}
      action={action}
    />
  );

  return (
    <>
      {renderList(
        listRagConfigurationWithType,
        ragColumns,
        t('configurations.rag_configs.title'),
        t('configurations.rag_configs.subtitle'),
        <Button
          label={t('configurations.create_config')}
          onClick={() => setVisible(true)}
          icon='icon-add'
          disabled={false}
        />
      )}
      {<ModalCreateRag visible={visible} onHide={() => setVisible(false)} />}
    </>
  );
};

export default ConfigurationsRag;
