import styled from 'styled-components';

export const IconTrigger = styled.div`
  position: relative;
  display: flex;
  border-radius: 36px;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(112, 145, 216, 0.1);
  }
`;
