import { Dialog } from 'primereact/dialog';
import Input from '../../../../../../Components/Input/Input';
import { useState } from 'react';
import { useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import Button from '../../../../../../Components/Button/Button';

interface ModalCreateSharepointProps {
  visible: boolean;
  onHide: () => void;
  handleAddSharepoint: (data) => void;
  handleUpdateSharepoint: (data) => void;
}

const ModalSharepoint = ({
  visible,
  onHide,
  handleAddSharepoint,
  handleUpdateSharepoint,
}: ModalCreateSharepointProps) => {
  const { selectedRagConfiguration } = useRagConfig();
  const [sharepointName, setSharepointName] = useState<string>('');
  const [sharepoinClientId, setSharepointClientId] = useState<string>('');
  const [sharepointClientSecret, setSharepointClientSecret] = useState<string>('');
  const [sharepointTenantId, setSharepointTenantId] = useState<string>('');
  const [sharepointSiteName, setSharepointSiteName] = useState<string>('');

  const sharePointSelectedData = selectedRagConfiguration?.content?.sources?.sharepoints?.find(
    (sharepoint) => sharepoint.name === sharepointName
  );

  console.log(sharePointSelectedData);

  const data = {
    name: sharepointName,
    clientId: sharepoinClientId,
    clientSecret: sharepointClientSecret,
    tenantId: sharepointTenantId,
    siteName: sharepointSiteName,
  };

  const isUpdate = selectedRagConfiguration?.content?.sources?.sharepoints?.find(
    (sharepoint) => sharepoint.name === sharepointName
  );

  return (
    <Dialog visible={visible} onHide={onHide} dismissableMask={true}>
      <>
        <h1>Informations du Sharepoint à Ajouter</h1>
        <Input
          value={sharepointName}
          onChange={(e) => setSharepointName(e.target.value)}
          placeholder={'Entrez le nom de votre Sharepoint'}
          label={'Nom du Sharepoint'}
          maxLength={50}
        />
        <Input
          value={sharepoinClientId}
          onChange={(e) => setSharepointClientId(e.target.value)}
          placeholder={'Client ID'}
          label={'Client ID'}
          maxLength={50}
        />
        <Input
          value={sharepointClientSecret}
          onChange={(e) => setSharepointClientSecret(e.target.value)}
          placeholder={'Client Secret'}
          label={'Client Secret'}
          maxLength={50}
        />
        <Input
          value={sharepointTenantId}
          onChange={(e) => setSharepointTenantId(e.target.value)}
          placeholder={'Tenant ID'}
          label={'Tenant ID'}
          maxLength={50}
        />
        <Input
          value={sharepointSiteName}
          onChange={(e) => setSharepointSiteName(e.target.value)}
          placeholder={'Site Name'}
          label={'Site Name'}
          maxLength={50}
        />
        {isUpdate ? (
          <Button label={'Mettre à jour'} onClick={() => handleUpdateSharepoint(data)} />
        ) : (
          <Button label={'Ajouter'} onClick={() => handleAddSharepoint(data)} />
        )}
      </>
    </Dialog>
  );
};
export default ModalSharepoint;
