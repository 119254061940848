import { Auth, Blank, Configurations, Edition, Logout, NewConfig, NoBot, ConfigurationsRag } from './Pages';
import { Navigate, useRoutes } from 'react-router-dom';

import { APP_PATH } from 'src/Tools/Constants';
import { useUserContext } from '../Contexts/UserContext';
import { encodedServiceUrl } from '../Tools/Api';

export const Router = () => {
  const { casClient } = useUserContext();

  console.log('router authent ' + (casClient.getToken ? 'yes ' : 'no ') + window.location.href);

  (async () => {
    // only usefull on callback call to validate ticket and persist token in session
    await casClient.checkSession(false, encodedServiceUrl);
  })();

  const unprotectedRoutes = useRoutes([
    { path: APP_PATH.AUTH, element: <Auth /> },
    { path: APP_PATH.CALLBACK, element: <Blank /> },
    { path: APP_PATH.ROOT, element: <Blank /> },
    { path: '*', element: <Navigate to={APP_PATH.ROOT} /> },
  ]);

  const protectedRoutes = useRoutes([
    { path: APP_PATH.AUTH, element: <Navigate to={APP_PATH.CONFIGURATIONS} /> },
    { path: APP_PATH.LOGOUT, element: <Logout /> },
    { path: APP_PATH.NO_BOT, element: <NoBot /> },
    { path: APP_PATH.EDIT, element: <Edition /> },
    { path: APP_PATH.NEW_CONFIG, element: <NewConfig /> },
    { path: APP_PATH.CONFIGURATIONS, element: <Configurations /> },
    { path: APP_PATH.CONFIG_RAG, element: <ConfigurationsRag /> },
    { path: '*', element: <Navigate to={APP_PATH.CONFIGURATIONS} /> },
  ]);

  return casClient.getToken() ? protectedRoutes : unprotectedRoutes;
};

export default Router;
