import Api from '@dydu_ai/dydu-api';
import { DyduStorage } from './Constants';
import axios, { AxiosResponse } from 'axios';

export const casUrl = window?.DYDU_TARGETS?.cas;
const url_callbot = window?.DYDU_TARGETS?.callbot_url;
// base64 of {"appWelcomeSentence":"cas.login.welcome.channels"}
export const casLoginMessage = 'eyJhcHBXZWxjb21lU2VudGVuY2UiOiJjYXMubG9naW4ud2VsY29tZS5jaGFubmVscyJ9';
export const casClientName = 'ChannelsCasClient';
export const encodedServiceUrl = encodeURIComponent(
  window.location.origin +
    (window.location.pathname !== '/' ? window.location.pathname : '') +
    '/?client_name=' +
    casClientName
);
const host = sessionStorage.getItem(DyduStorage.HOST) || process.env.REACT_APP_SERVERCODE;

const getGatewayUrl = () => {
  if (sessionStorage.getItem(DyduStorage.PROXY)) {
    return sessionStorage.getItem(DyduStorage.PROXY);
  } else if (window?.DYDU_TARGETS?.backend) {
    return window?.DYDU_TARGETS?.backend;
  }
  return process.env.REACT_APP_GATEWAY_URL;
};

export function getClientApi() {
  const headers = {};
  return Api.getInstance(getGatewayUrl(), headers);
}

export const getCallBotConfigurations = async (atriaServer: string, botUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.get(url_callbot + `/instances/${atriaServer}/${botUUID}`, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateCallBotVoice = async (instanceUUID: string, data: object) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.post(url_callbot + `/instances/${instanceUUID}`, data, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllCallBotVoicesAvailables = async (instanceUUID: string) => {
  const headers = {
    accept: 'application/json',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  try {
    const res: AxiosResponse = await axios.get(url_callbot + `/tts/voices/${instanceUUID}`, { headers });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const generateTts = async (
  instanceUUID: string,
  voice: string,
  content: string,
  provider: string,
  language: string
) => {
  const headers = {
    accept: 'application/octet-stream',
    Authorization: `Basic ZHlkdTpLMEdGMUpSL2lYMWtwRWcrektTTForR1l6TVk=`,
  };
  const params = {
    provider: provider,
    language: language,
    voice: voice,
    content: content,
  };
  const responseType = 'blob';
  try {
    const res: AxiosResponse = await axios.post(url_callbot + `/tts/generate/${instanceUUID}`, '', {
      headers,
      params,
      responseType,
    });
    return res.data;
  } catch (error) {
    return error;
  }
};

export const createRagConfig = async (data: object, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer' + ' ' + token,
  };
  try {
    const res: AxiosResponse = await axios.post(
      'https://dev1.cloud.dydu-priv.com/gateway/api/dydubox_backend/rag',
      data,
      {
        headers,
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getRagConfigurationsForBotUuid = async (botUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer' + ' ' + token,
  };
  try {
    const res: AxiosResponse = await axios.get(
      `https://dev1.cloud.dydu-priv.com/gateway/api/dydubox_backend/rag/bot/${botUUID}`,
      {
        headers,
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const deleteRagConfiguration = async (ragUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer' + ' ' + token,
  };
  try {
    const res: AxiosResponse = await axios.delete(
      `https://dev1.cloud.dydu-priv.com/gateway/api/dydubox_backend/rag/${ragUUID}`,
      {
        headers,
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const deleteRagConfigurationSource = async (ragUUID: string, sourceUUID: string, token: string) => {
  const headers = {
    accept: 'application/json',
    servercode: host,
    authorization: 'Bearer' + ' ' + token,
  };
  try {
    const res: AxiosResponse = await axios.delete(
      `https://dev1.cloud.dydu-priv.com/gateway/api/dydubox_backend/rag/${ragUUID}/${sourceUUID}`,
      {
        headers,
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addRagConfigurationSourceFile = async (
  ragUUID: string,
  data: string,
  token: string,
  onProgress: (progress: number) => void
) => {
  const headers = {
    accept: '*/*',
    'content-type': 'multipart/form-data',
    servercode: host,
    authorization: 'Bearer' + ' ' + token,
  };
  try {
    const formData = new FormData();
    formData.append('file', data);
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      },
      headers,
    };
    const res: AxiosResponse = await axios.post(
      `https://dev1.cloud.dydu-priv.com/gateway/api/dydubox_backend/rag/${ragUUID}/file`,
      formData,
      config
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const addRagConfigurationSharePoint = async (ragUUID: string, data: object) => {
  const headers = {
    accept: 'application/json',
    servercode: 'dev.neptune.doyoudreamup.com',
    authorization:
      'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtaGF6aW1lQGR5ZHUuYWkiLCJib3VzZXIiOnRydWUsImV4cCI6MTcyMjQzODc2Miwiand0SXNzdWVyU2VydmVyIjoiZGV2Lm5lcHR1bmUuZG95b3VkcmVhbXVwLmNvbSIsInV1aWQiOiI4NzE3MWNmYy00MmQ5LTQxZWUtYmFmYi0xYzhmM2U1OTJmOGIiLCJpYXQiOjE3MjI0MDk5NjIsIm9yZGluYWwiOjB9.UmFgBhm4LfKANHOZk7zVK_kZWE8PbrgVD62Q4BZrl3dl6w9r2R9QaRt7ig1B54pLUxFBt5a8TsaiiQpCQqIWRA',
  };
  try {
    const res: AxiosResponse = await axios.post(
      `https://dev1.cloud.dydu-priv.com/gateway/api/dydubox_backend/rag/${ragUUID}/sharepoint`,
      data,
      {
        headers,
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateRagConfigurationSharepoint = async (ragUUID: string, data: object) => {
  const headers = {
    accept: 'application/json',
    servercode: 'dev.neptune.doyoudreamup.com',
    authorization:
      'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtaGF6aW1lQGR5ZHUuYWkiLCJib3VzZXIiOnRydWUsImV4cCI6MTcyMjQzODc2Miwiand0SXNzdWVyU2VydmVyIjoiZGV2Lm5lcHR1bmUuZG95b3VkcmVhbXVwLmNvbSIsInV1aWQiOiI4NzE3MWNmYy00MmQ5LTQxZWUtYmFmYi0xYzhmM2U1OTJmOGIiLCJpYXQiOjE3MjI0MDk5NjIsIm9yZGluYWwiOjB9.UmFgBhm4LfKANHOZk7zVK_kZWE8PbrgVD62Q4BZrl3dl6w9r2R9QaRt7ig1B54pLUxFBt5a8TsaiiQpCQqIWRA',
  };
  try {
    const res: AxiosResponse = await axios.put(
      `https://dev1.cloud.dydu-priv.com/gateway/api/dydubox_backend/rag/${ragUUID}/sharepoint`,
      data,
      {
        headers,
      }
    );
    return res.data;
  } catch (error) {
    return error;
  }
};
