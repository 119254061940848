import { useLocation, useNavigate } from 'react-router-dom';

import { APP_PATH } from 'src/Tools/Constants';
import Breadcrumb from './Breadcrumb';
import { getSelectedConfig } from 'src/Redux/Configurations/selectors';
import { useSelector } from 'react-redux';
import { useRagConfig } from '../../Contexts/RagConfigContext';

export interface BreadcrumbContainerProps {
  selectedConfig?: Models.ConfigTypes;
  pathname: string;
  redirectToHome: () => void;
}

const BreadcrumbContainer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedRagConfiguration } = useRagConfig();

  const selectedConfig = useSelector(getSelectedConfig);

  const handleRedirectToHome = () => {
    if (location.pathname.includes(APP_PATH.CONFIG_RAG) || selectedRagConfiguration) {
      navigate(APP_PATH.CONFIG_RAG);
    } else navigate(APP_PATH.CONFIGURATIONS);
  };

  const props: BreadcrumbContainerProps = {
    selectedConfig,
    pathname: location.pathname,
    redirectToHome: handleRedirectToHome,
  };

  return <Breadcrumb {...props} />;
};

export default BreadcrumbContainer;
