import { Panel } from 'primereact/panel';
import { useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useUserContext } from '../../../../../../Contexts/UserContext';
import { toastError, toastSuccess } from '../../../../../../Tools/Toast';
import {
  addRagConfigurationSharePoint,
  deleteRagConfigurationSource,
  updateRagConfigurationSharepoint,
} from '../../../../../../Tools/Api';
import { IconTrigger } from '../../../../../ConfigurationsRag/Style';
import Icon from '../../../../../../Components/Icon/Icon';
import { PrimeIcons } from 'primereact/api';
import DataTable from '../../../../../../Components/DataTable/DataTable';
import Button from '../../../../../../Components/Button/Button';
import ModalSharepoint from './ModalSharepoint';

const SharePointList = () => {
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();
  const [visible, setVisible] = useState<boolean>(false);
  const [collapsed] = useState<boolean>(false);
  const { t } = useTranslation();
  const { casClient } = useUserContext();
  const token = casClient.getToken();
  const sharePointContent = selectedRagConfiguration?.content?.sources?.sharepoints?.map((sharepoint) => {
    return sharepoint;
  });
  const sharePointColumns = [
    { id: 'name', title: 'Name' },
    {
      id: 'addedBy',
      title: 'Added By',
    },
    {
      id: 'addedAt',
      title: 'Date de création',
      body: (rowData: any) => {
        return new Date(rowData.addedAt).toLocaleDateString();
      },
    },
    {
      id: 'actions',
      title: 'Actions',
      body: (rowData: any) => {
        return (
          <IconTrigger
            onClick={(event) => {
              event.stopPropagation();
              selectedRagConfiguration &&
                deleteRagConfigurationSource(selectedRagConfiguration?.id, rowData.id, token)
                  .then(() => {
                    setSelectedRagConfiguration &&
                      setSelectedRagConfiguration({
                        ...selectedRagConfiguration,
                        content: {
                          ...selectedRagConfiguration.content,
                          sources: {
                            ...selectedRagConfiguration.content?.sources,
                            sharepoints: selectedRagConfiguration.content?.sources?.sharepoints?.filter((file) => {
                              return file.id !== rowData.id;
                            }),
                          },
                        },
                      });
                    toastSuccess(t('general.FilesList.fileDeleted'));
                  })
                  .catch(() => {
                    toastError(t('general.FilesList.errorDeleteFile'));
                  });
            }}
          >
            <Icon color='red' icon='icon-trash' size={55} />
          </IconTrigger>
        );
      },
    },
  ];
  const handleAddSharepoint = (data) => {
    selectedRagConfiguration &&
      addRagConfigurationSharePoint(selectedRagConfiguration?.id, data)
        .then((res) => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  sharepoints: [...(selectedRagConfiguration.content?.sources?.sharepoints || []), res],
                },
              },
            });
          toastSuccess('Sharepoint ajouté avec succès');
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du Sharepoint");
        });
  };

  const handleUpdateSharepoint = (data) => {
    selectedRagConfiguration &&
      updateRagConfigurationSharepoint(selectedRagConfiguration?.id, data)
        .then((res) => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  sharepoints: [...(selectedRagConfiguration.content?.sources?.sharepoints || []), res],
                },
              },
            });
          toastSuccess('Sharepoint ajouté avec succès');
        })
        .catch(() => {
          toastError("Erreur lors de l'ajout du Sharepoint");
        });
  };

  const renderSharePointPanelHeader = () => {
    return (
      <>
        <Button
          label={'Ajouter un Sharepoint'}
          icon='pi pi-plus'
          onClick={() => {
            setVisible(true);
          }}
        />
        <ModalSharepoint
          visible={visible}
          onHide={() => setVisible(false)}
          handleAddSharepoint={handleAddSharepoint}
          handleUpdateSharepoint={handleUpdateSharepoint}
        />
      </>
    );
  };

  return (
    <>
      <Panel
        header={renderSharePointPanelHeader()}
        toggleable
        collapsed={collapsed}
        key={'filesRag'}
        expandIcon={PrimeIcons.CHEVRON_DOWN}
        collapseIcon={PrimeIcons.CHEVRON_UP}
      >
        <DataTable
          data={sharePointContent}
          columns={sharePointColumns}
          rowClassName={null}
          onRowClick={(data) => {
            console.log('row clicked' + data);
            setVisible(true);
          }}
        />
      </Panel>
    </>
  );
};

export default SharePointList;
