import styled from 'styled-components';

export const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-bottom: 60px;

  .p-panel {
    width: 100%;
  }

  .p-panel-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    width: 100%;
  }

  .p-panel-subtitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
  }

  .uploadFile-header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 20px;
  }

  .p-fileupload-file-badge,
  .p-fileupload-filename {
    font-size: 12px;
  }
`;
