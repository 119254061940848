import { Panel } from 'primereact/panel';
import DataTable from '../../../../../../Components/DataTable/DataTable';
import { useRagConfig } from '../../../../../../Contexts/RagConfigContext';
import { useTranslation } from 'react-i18next';
import { PrimeIcons } from 'primereact/api';
import { IconTrigger } from '../../../../../ConfigurationsRag/Style';
import Icon from '../../../../../../Components/Icon/Icon';
import { addRagConfigurationSourceFile, deleteRagConfigurationSource } from '../../../../../../Tools/Api';
import { FileUpload } from 'primereact/fileupload';
import { useRef, useState } from 'react';
import { toastError, toastSuccess } from '../../../../../../Tools/Toast';
import { ProgressBar } from 'primereact/progressbar';
import { useUserContext } from '../../../../../../Contexts/UserContext';

const FilesList = () => {
  const { selectedRagConfiguration, setSelectedRagConfiguration } = useRagConfig();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { t } = useTranslation();
  const { casClient } = useUserContext();
  const token = casClient.getToken();
  const filesContent = selectedRagConfiguration?.content?.sources?.files?.map((file) => {
    return file;
  });
  const fileUploadRef = useRef<FileUpload>(null);

  const fileColumns = [
    { id: 'name', title: 'Name' },
    {
      id: 'addedBy',
      title: 'Added By',
    },
    {
      id: 'addedAt',
      title: 'Date de création',
      body: (rowData: any) => {
        return new Date(rowData.addedAt).toLocaleDateString();
      },
    },
    {
      id: 'actions',
      title: 'Actions',
      body: (rowData: any) => {
        return (
          <IconTrigger
            onClick={(event) => {
              event.stopPropagation();
              selectedRagConfiguration &&
                deleteRagConfigurationSource(selectedRagConfiguration?.id, rowData.id, token)
                  .then(() => {
                    setSelectedRagConfiguration &&
                      setSelectedRagConfiguration({
                        ...selectedRagConfiguration,
                        content: {
                          ...selectedRagConfiguration.content,
                          sources: {
                            ...selectedRagConfiguration.content?.sources,
                            files: selectedRagConfiguration.content?.sources?.files?.filter((file) => {
                              return file.id !== rowData.id;
                            }),
                          },
                        },
                      });
                  })
                  .then(() => {
                    toastSuccess('File deleted successfully');
                  })
                  .catch(() => {
                    toastError('Error while deleting file');
                  });
            }}
          >
            <Icon color='red' icon='icon-trash' size={55} />
          </IconTrigger>
        );
      },
    },
  ];

  const uploadHandler = (e: any) => {
    selectedRagConfiguration &&
      addRagConfigurationSourceFile(selectedRagConfiguration?.id, e.files[0], token, (progress) => {
        setUploadProgress(progress);
      })
        .then((res) => {
          setSelectedRagConfiguration &&
            setSelectedRagConfiguration({
              ...selectedRagConfiguration,
              content: {
                ...selectedRagConfiguration.content,
                sources: {
                  ...selectedRagConfiguration.content?.sources,
                  files: [...(selectedRagConfiguration.content?.sources?.files || []), res],
                },
              },
            });
        })
        .then(() => {
          if (fileUploadRef.current) fileUploadRef.current.clear();
          setUploadProgress(0);
          toastSuccess(t('config_rag.uploadFile.uploadSuccess'));
        })
        .catch(() => {
          if (fileUploadRef.current) fileUploadRef.current.clear();
          toastError(t('config_rag.uploadFile.uploadError'));
        });
  };
  const renderProgressBar = () => {
    return <ProgressBar value={uploadProgress} />;
  };
  const renderFilesPanelHeader = () => {
    return (
      <div className={'uploadFile-header'}>
        <div>
          <span className='p-panel-title'>{t('config_rag.uploadFile.title')}</span>
          <span className='p-panel-subtitle'>{t('config_rag.uploadFile.subtitle')}</span>
        </div>
        <FileUpload
          ref={fileUploadRef}
          progressBarTemplate={renderProgressBar()}
          name={'file'}
          maxFileSize={10485760}
          customUpload
          uploadHandler={uploadHandler}
          accept='*/*'
          chooseLabel={t('config_rag.uploadFile.choose')}
          uploadLabel={t('config_rag.uploadFile.upload')}
          cancelLabel={t('config_rag.uploadFile.cancel')}
        />
      </div>
    );
  };
  return (
    <>
      <Panel
        header={renderFilesPanelHeader()}
        toggleable
        collapsed={true}
        key={'filesRag'}
        expandIcon={PrimeIcons.CHEVRON_DOWN}
        collapseIcon={PrimeIcons.CHEVRON_UP}
      >
        <DataTable data={filesContent} columns={fileColumns} rowClassName={null} />
      </Panel>
    </>
  );
};

export default FilesList;
