import Icon from '../../../../../Components/Icon/Icon';
import { updateCallBotVoice } from '../../../../../Tools/Api';
import { useCallBotConfigInfo } from '../../../../../Contexts/CallBotContext';
import { toastError, toastSuccess } from '../../../../../Tools/Toast';
import { useTranslation } from 'react-i18next';

interface SelectNewVoiceProps {
  voice: any;
}

const SelectNewVoice = ({ voice }: SelectNewVoiceProps) => {
  const { selectedCallBotConfig, setSelectedCallBotConfig, setCallBotConfigurations } = useCallBotConfigInfo();
  const { t } = useTranslation();

  const newVoiceForSelectedConfig = {
    ...selectedCallBotConfig,
    tts: {
      ...selectedCallBotConfig.tts,
      name: voice.id,
      provider: voice.provider,
    },
  };

  const handleSelectVoice = () => {
    updateCallBotVoice(selectedCallBotConfig.uuid, newVoiceForSelectedConfig)
      .then(() => {
        setSelectedCallBotConfig(newVoiceForSelectedConfig);
        setCallBotConfigurations((prev) => {
          return prev.map((config) => {
            if (config.uuid === newVoiceForSelectedConfig.uuid) {
              return newVoiceForSelectedConfig;
            }
            return config;
          });
        });
        toastSuccess(t('edition.callbot.voiceUpdated'));
      })
      .catch(() => {
        toastError(t('edition.callbot.voiceNotUpdated'));
      });
  };
  return (
    <Icon
      icon={'check'}
      size={20}
      color='#3636B9'
      onClick={() => {
        handleSelectVoice();
      }}
    />
  );
};

export default SelectNewVoice;
