import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentBot } from '../Redux/Bot/selectors';
import { getRagConfigurationsForBotUuid } from '../Tools/Api';
import { useUserContext } from './UserContext';

interface RagConfigProviderProps {
  ragConfigs?: RagConfigProps[];
  setRagConfigs?: (ragConfig: RagConfigProps[]) => void;
  selectedRagConfiguration?: RagConfigProps | null;
  setSelectedRagConfiguration?: (ragConfig: RagConfigProps | null) => void | null;
}

interface File {
  name: string;
  id: string;
  addedBy: string;
  addedAt: number;
  storageUUID: string;
}

interface Sharepoint {
  id: string;
  name: string;
  addedBy: string;
  addedAt: number;
  clientId: string;
  clientSecret: string;
  tenantId: string;
  siteId: string;
  siteName: string;
}

interface Content {
  sources: Sources;
}

interface Sources {
  files?: File[];
  sharepoints?: Sharepoint[];
}

export interface RagConfigProps {
  id: string;
  name: string | null;
  serverCode?: string;
  botUUID: string | null;
  createdAt?: string;
  updatedAt?: string;
  author?: string;
  modifiedBy?: string;
  content?: Content;
}

export const useRagConfig = () => useContext<RagConfigProviderProps>(RagConfigContext);
export const RagConfigContext = createContext({} as RagConfigProviderProps);

const RagConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const [ragConfigs, setRagConfigs] = useState<RagConfigProps[]>([]);
  const [selectedRagConfiguration, setSelectedRagConfiguration] = useState<RagConfigProps | null>(null);
  const botUUID = useSelector(getCurrentBot)?.botUUID;
  const { casClient } = useUserContext();
  const token = casClient.getToken();

  useEffect(() => {
    if (botUUID) {
      getRagConfigurationsForBotUuid(botUUID, token)
        .then((res: any) => {
          setRagConfigs(res);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [botUUID]);

  useEffect(() => {
    if (selectedRagConfiguration) {
      setRagConfigs(
        ragConfigs?.map((config) => (config.id === selectedRagConfiguration.id ? selectedRagConfiguration : config))
      );
    }
  }, [selectedRagConfiguration]);

  const props = {
    ragConfigs,
    setRagConfigs,
    selectedRagConfiguration,
    setSelectedRagConfiguration,
  };

  return <RagConfigContext.Provider children={children} value={props} />;
};

export default RagConfigProvider;
