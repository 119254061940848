import { CONNECTOR_CONFIGURATION, WEBSITE_CONFIGURATION } from 'src/Redux/Configurations/types';
import { useDispatch, useSelector } from 'react-redux';

import { ActionProps } from 'src/Components/Actions/Actions';
import { CONFIG_TYPES } from 'src/Tools/Constants';
import { MainContainer } from './Style';
import UpdateName from './UpdateName';
import { closeModal } from 'src/Redux/Modal/actions';
import { getLoadingStatuses } from 'src/Redux/Loading/selectors';
import { updateConnectorConfigurationName } from 'src/Redux/Configurations/actions/connectorActions';
import { updateWebsiteConfigurationName } from 'src/Redux/Configurations/actions/websiteActions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface UpdateNameContainerProps {
  config: Models.ConfigTypes;
}

export interface UpdateNameProps {
  actions: ActionProps[];
  configName: string;
  setConfigName: (name: string) => void;
  updateConfigName?: () => void;
  className?: string;
  label?: string;
  placeholder?: string;
}

const UpdateNameContainer = ({ config }: UpdateNameContainerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [configName, setConfigName] = useState(config.name || '');
  const loading = useSelector((state) =>
    getLoadingStatuses(state, [WEBSITE_CONFIGURATION.UPDATE_NAME.REQUEST, CONNECTOR_CONFIGURATION.UPDATE_NAME.REQUEST])
  );

  const handleUpdateConfigName = () => {
    if (configName && config.id && config.type) {
      return [CONFIG_TYPES.WEBSITE, CONFIG_TYPES.CUSTOMER].includes(config.type)
        ? dispatch(updateWebsiteConfigurationName.request(configName, config.id))
        : dispatch(updateConnectorConfigurationName.request(configName, config.id));
    }
  };

  const actions: ActionProps[] = [
    {
      label: t('general.cancel'),
      className: 'p-button-text p-button-secondary',
      icon: 'icon-close',
      onClick: () => dispatch(closeModal()),
    },
    {
      label: t('general.save'),
      loading,
      icon: 'icon-check',
      disabled: configName.length < 1,
      onClick: handleUpdateConfigName,
    },
  ];

  const props: UpdateNameProps = {
    actions,
    configName,
    setConfigName,
    updateConfigName: handleUpdateConfigName,
  };

  return (
    <MainContainer>
      <UpdateName {...props} />
    </MainContainer>
  );
};

export default UpdateNameContainer;
